import {
  EODData,
  ETFMeta,
  ISIN,
  Security,
  SignalAlgorithm,
  SignalIdFromString,
  StockMeta,
  Ticker,
  TickerComment,
  TickerData,
  TickerSymbol,
  Translated
} from "#models/Security"
import { ATRSignal, PerformanceValue, RollingSignal, SimpleSignal } from "#models/Signals"
import { User } from "#models/User"
import { S } from "#resources/lib"
import { NonEmptyString255 } from "effect-app/Schema"

export const CannotSubscribeAlgorithmReason = S.Literal(
  "no_plan_subscription_limit",
  "free_plan_subscription_limit",
  "max_subscriptions_reached"
)
export type CannotSubscribeAlgorithmReason = typeof CannotSubscribeAlgorithmReason.Type
export class SubscriptionError extends S.TaggedError<SubscriptionError>()("SubscriptionError", {
  reason: CannotSubscribeAlgorithmReason
}) {
  constructor(
    reasonOrObject: CannotSubscribeAlgorithmReason | { reason: CannotSubscribeAlgorithmReason },
    disableValidation?: boolean
  ) {
    super(
      typeof reasonOrObject === "object" ? reasonOrObject : { reason: reasonOrObject },
      disableValidation
    )
  }

  override get message() {
    return `Cannot apply change due to Subscription limitation: ${this.reason}`
  }
}

// Add comment
export class AddComment extends S.Req<AddComment>()("AddComment", {
  ...TickerComment.omit("id", "createdAt", "author")
}, { allowedRoles: ["user"] }) {}

// Add to portfolio
export class AddToPortfolio extends S.Req<AddToPortfolio>()("AddToPortfolio", {
  symbol: TickerSymbol
}, { allowedRoles: ["user"], failure: SubscriptionError }) {}

// Get
export class Author extends S.Class<Author>()({
  ...User.pick("id", "displayName")
}) {}

export class TickerCommentWithName extends S.Class<TickerCommentWithName>()({
  ...TickerComment.omit("author"),
  author: Author
}) {}

export class TickerView extends S.Class<TickerView>()({
  ...TickerData.fields,
  subscribedAlgorithms: S.ReadonlySet(SignalAlgorithm),
  isInPortfolio: S.Boolean,
  owned: S.Boolean,
  comments: S.Array(TickerCommentWithName),
  isin: ISIN,
  logo: S.NullOr(S.Url),
  summary: S.NullOr(Translated)
}) {}

export class Get extends S.Req<Get>()("Get", {
  symbol: TickerSymbol
}, { allowedRoles: ["user"], success: TickerView }) {}

// Get EOD
export class GetEodResponse extends S.Class<GetEodResponse>()({
  data: S.Array(EODData)
}) {}

export class GetEod extends S.Req<GetEod>()("GetEod", {
  symbol: TickerSymbol
}, { allowedRoles: ["user"], success: GetEodResponse }) {}

// Get Etf
export class TickerWithEtf extends S.Class<TickerWithEtf>()({
  symbol: TickerSymbol,
  ...Security.pick("isin", "name", "description", "sector", "logo", "summary"),
  ...ETFMeta.fields,
  style: S.NullOr(S.String),
  region: S.String
}) {}

export class GetEtfResponse extends S.Class<GetEtfResponse>()({
  items: S.Array(TickerWithEtf)
}) {}

export class GetEtfs extends S.Req<GetEtfs>()("GetEtfs", {}, { allowedRoles: ["user"], success: GetEtfResponse }) {}

// Get Index

export class TickerWithStock extends S.Class<TickerWithStock>()({
  symbol: TickerSymbol,
  ...Security.pick("isin", "name", "description", "sector", "logo", "summary"),
  ...StockMeta.fields,
  sector: S.NullOr(S.String),
  description: S.NullOr(S.String),
  summary: S.NullOr(S.String).withDefault
}) {}

export class GetIndexResponse extends S.Class<GetIndexResponse>()({
  items: S.Array(TickerWithStock)
}) {}

export class GetIndex extends S.Req<GetIndex>()("GetIndex", {
  query: NonEmptyString255
}, { allowedRoles: ["user"], success: GetIndexResponse }) {}

// Get Signal Data
export class GetSignalChartResponse extends S.Class<GetSignalChartResponse>()({
  data: S.Union(S.Array(SimpleSignal), S.Array(ATRSignal), S.Array(RollingSignal)),
  performanceData: S.Array(PerformanceValue)
}) {}

export class GetSignalData extends S.Req<GetSignalData>()("GetSignalData", {
  id: SignalIdFromString
}, { allowedRoles: ["user"], success: GetSignalChartResponse }) {}

// Remove From Portfolio
export class RemoveFromPortfolio extends S.Req<RemoveFromPortfolio>()("RemoveFromPortfolio", {
  symbol: TickerSymbol
}, { allowedRoles: ["user"] }) {}

// Search
export const TickerSearchItem = S.Struct({
  symbol: Ticker.fields.symbol,
  ...TickerData.omit("symbol")
})
export type TickerSearchItem = typeof TickerSearchItem.Type

export class SearchTickerResponse extends S.Class<SearchTickerResponse>()({
  items: S.Array(TickerSearchItem)
}) {}

export class Search extends S.Req<Search>()("Search", {
  query: NonEmptyString255
}, { allowedRoles: ["user"], success: SearchTickerResponse }) {}

export class HighlightsResponse extends S.Class<HighlightsResponse>()({
  items: S.Array(TickerSearchItem)
}) {}

export class Highlights extends S.Req<Highlights>()("Highlights", {}, {
  allowedRoles: ["user"],
  success: HighlightsResponse
}) {}

// Subscribe Algorithm
export class SubscribeAlgorithm extends S.Req<SubscribeAlgorithm>()("SubscribeAlgorithm", {
  symbol: TickerSymbol,
  algorithm: SignalAlgorithm
}, { allowedRoles: ["user"], failure: SubscriptionError }) {}

// Unsubscribe Algorithm
export class UnsubscribeAlgorithm extends S.Req<UnsubscribeAlgorithm>()("UnsubscribeAlgorithm", {
  symbol: TickerSymbol,
  algorithm: SignalAlgorithm
}, { allowedRoles: ["user"], failure: SubscriptionError }) {}

// Update Owned
export class UpdateOwned extends S.Req<UpdateOwned>()("UpdateOwned", {
  symbol: TickerSymbol,
  owned: S.Boolean
}, { allowedRoles: ["user"], failure: SubscriptionError }) {}

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "Tickers" } as const
// codegen:end
