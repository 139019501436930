import { NonEmptyTranslated, Ticker } from "#models/Security"
import { StockExchange } from "#models/StockExchange"
import { S } from "#resources/lib"
import { NonEmptyString255 } from "effect-app/Schema"
import { TickerWithEtf, TickerWithStock } from "./Tickers.js"

export class StockTickerV3 extends S.Class<StockTickerV3>()({
  ...TickerWithStock.fields,
  stockExchange: StockExchange
}) {}

export class EtfTickerV3 extends S.Class<EtfTickerV3>()({
  ...TickerWithEtf.fields,
  stockExchange: StockExchange
}) {}

/**
 * A specialized version of `EtfTickerV3` that includes the group name.
 * We only use the groupNames for the search, but not in other APIs.
 */
export class EtfTickerSearchItemV3 extends S.Class<EtfTickerSearchItemV3>()({
  ...EtfTickerV3.fields,
  groupName: S.NullOr(S.String)
}) {}

/**
 * A specialized version of `StockTickerV3` that includes the group name.
 * We only use the groupNames for the search, but not in other APIs.
 */
export class StockTickerSearchItemV3 extends S.Class<StockTickerSearchItemV3>()({
  ...StockTickerV3.fields,
  groupName: S.NullOr(S.NonEmptyString)
}) {}

export const TickerItemV3 = S.Union(EtfTickerV3, StockTickerV3)
export type TickerItemV3 = typeof TickerItemV3.Type

export const TickerSearchItemV3 = S.Union(EtfTickerSearchItemV3, StockTickerSearchItemV3)
export type TickerSearchItemV3 = typeof TickerSearchItemV3.Type

export class GetEtfResponseV3 extends S.Class<GetEtfResponseV3>()({
  items: S.Array(EtfTickerV3)
}) {}

export class GetIndexResponseV3 extends S.Class<GetIndexResponseV3>()({
  items: S.Array(StockTickerV3)
}) {}

export class GetETFsV3 extends S.Req<GetETFsV3>()("GetETFsV3", {}, {
  allowedRoles: ["user"],
  success: GetEtfResponseV3
}) {}

export class GetMostPopularResponseV3 extends S.Class<GetMostPopularResponseV3>()({
  items: S.Array(TickerItemV3)
}) {}

export class GetMostPopularV3 extends S.Req<GetMostPopularV3>()("GetMostPopularV3", {}, {
  allowedRoles: ["user"],
  success: GetMostPopularResponseV3
}) {}

export class GetIndexV3 extends S.Req<GetIndexV3>()("GetIndexV3", {
  query: NonEmptyString255
}, {
  allowedRoles: ["user"],
  success: GetIndexResponseV3
}) {}

export class SearchV3Response extends S.Class<SearchV3Response>()({
  items: S.Array(TickerSearchItemV3)
}) {}

export class SearchV3 extends S.Req<SearchV3>()("SearchV3", {
  query: NonEmptyString255
}, {
  allowedRoles: ["user"],
  success: SearchV3Response
}) {}

// TODO: Remove this in favor of basic search
export class HighlightsV3 extends S.Req<HighlightsV3>()("HighlightsV3", {}, {
  allowedRoles: ["user"],
  success: SearchV3Response
}) {}

export class TickerGroup extends S.Class<TickerGroup>()({
  id: S.StringId.withDefault,
  group: NonEmptyTranslated,
  items: S.Array(Ticker.fields.symbol),
  isInHighlights: S.Boolean.withDefault
}) {}

export class TickerGroupView extends S.Class<TickerGroupView>()({
  ...TickerGroup.fields,
  items: S.Array(TickerSearchItemV3)
}) {}

export class CreateTickerGroup extends S.Req<CreateTickerGroup>()("CreateTickerGroup", {
  group: TickerGroup.fields.group
}, { allowedRoles: ["manager"] }) {}

export class GetTickerGroup extends S.Req<GetTickerGroup>()("GetTickerGroup", {
  id: TickerGroup.fields.id
}, { allowedRoles: ["manager"], success: TickerGroupView }) {}

export class UpdateTickerGroup extends S.Req<UpdateTickerGroup>()("UpdateTickerGroup", {
  ...TickerGroup.fields
}, { allowedRoles: ["manager"] }) {}

export class DeleteTickerGroup extends S.Req<DeleteTickerGroup>()("DeleteTickerGroup", {
  id: TickerGroup.fields.id
}, { allowedRoles: ["manager"] }) {}

export class IndexTickerGroups extends S.Req<IndexTickerGroups>()("IndexTickerGroups", {}, {
  allowedRoles: ["manager"],
  success: S.Struct({ items: S.Array(TickerGroupView) })
}) {}

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "TickersV3" } as const
// codegen:end
