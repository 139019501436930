import type { Email } from "effect-app/Schema"
import type { Locale, Role, UserId } from "#models/User"
import { ref, computed } from "vue"
import { useAuth } from "#imports"

/**
 * We would like the locale to be part of the user session,
 * but we want to delay implementing calling auth0 management api
 * and adding locale to JWT.
 */

export const currentLocale = ref(getLocale())

function getLocale() {
  return (window.localStorage.getItem("locale") as Locale | null) || "de"
}

window.addEventListener("storage", () => {
  refreshLocaleIfNeeded(getLocale())
})

function refreshLocaleIfNeeded(locale: Locale) {
  if (currentLocale.value !== locale) {
    window.location.reload()
  }
}

export function setLocale(locale: Locale) {
  localStorage.setItem("locale", locale)
  refreshLocaleIfNeeded(locale)
}

export interface UserSession {
  id: UserId
  email: Email
  image: string
  name: string
  role: Role
  locale: Locale
}
export function useAuthUser(): UserSession {
  const user = useOptionalSessionUser()
  if (!user.value) {
    throw new Error("Accessed user while not authenticated")
  }
  return user.value
}

export function useOptionalSessionUser() {
  const s = useAuth()

  return computed<UserSession | null>(() => {
    if (!s.data.value) {
      return null
    }

    // TODO
    return {
      ...(s.data.value.user as unknown as UserSession),
      locale: currentLocale.value,
    }
  })
}
