import { computed, watch } from "vue"

import { LocaleContext } from "~/composables/intl"
import { useOptionalSessionUser } from "./currentUser"
import { customSchemaErrors } from "@effect-app/vue/form"

export const setup = () => {
  const u = useOptionalSessionUser()
  const locale = computed(() => u.value?.locale ?? "de")
  LocaleContext.provide(locale)

  watch(
    locale,
    locale => {
      customSchemaErrors.value.set(
        "ISIN",
        locale === "de"
          ? () => "erwarte eine gültige ISIN wie 'US0378331005'"
          : () => "expected a valid ISIN like 'US0378331005'",
      )
      customSchemaErrors.value.set(
        "PasswordInput",
        locale === "de"
          ? () => "min 8, Großbuchstabe, Kleinbuchstabe, Zahl, Sonderzeichen"
          : () => "min 8, upper, lower, number, special char",
      )
    },
    { immediate: true },
  )
}
