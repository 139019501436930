import {
  IntradayData,
  PortfolioTicker,
  PortfolioV2,
  Recommendation,
  SignalAlgorithm,
  TickerData,
  TickerSymbol
} from "#models/Security"
import { S } from "#resources/lib"

export class TickerWithEodData extends S.Class<TickerWithEodData>()({
  ...TickerData.fields,
  intraday: S.NullOr(IntradayData)
}) {}

export class SubscriptionWithTickerV2 extends S.Class<SubscriptionWithTickerV2>()({
  ...PortfolioTicker.fields,
  symbol: TickerSymbol,
  tickerData: S.NullOr(TickerWithEodData),
  signalData: S.ReadonlyMap({ key: SignalAlgorithm, value: S.NullOr(Recommendation) })
}) {}

export class IndexSubscriptionsV2 extends S.Req<IndexSubscriptionsV2>()("IndexSubscriptionsV2", {}, {
  allowedRoles: ["user"],
  success: S.Struct({
    ...PortfolioV2.fields,
    items: S.Array(SubscriptionWithTickerV2)
  })
}) {}

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "SubscriptionsV2" } as const
// codegen:end
