import revive_payload_client_stGwXapwgk from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MRI9ICrv4t from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eCwvkSMteU from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rHpx0jiAot from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ImPvCSBVf1 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_J5fogEd6UE from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RwuWkKbU0J from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_KcpuzevjT8 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_@parcel+watcher@2.4.1_@types+node_5mouns3n4g3i4xaacjc5gwl64a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_yJlTz5TycN from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.24.4_vite@6.0.3_@types+node@22.10.1_jiti@2.4.0__swhgr5u6ne4iuk3prvxavzpkbi/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_5N3yHtt5xY from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_patch_hash=5dcq766eeuesmqdcvoqrnf6tzi_@tanstack+vue-query@5_vqu65m7jgk2zca7x7b5scqumka/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_jAqsr7TQRA from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_@azure+cosmos@4.2.0_@azure+storage-blob@12.26.0_encoding@0.1.13_mag_q7vedu2y5vyy4w3v4undnkykk4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_pBo3AeJLa4 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@azure+cosmos@4.2.0_@azure+storag_ofy23jlii6zupcb6ufrd4atv7a/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import runtime_wNIQkZlad8 from "/home/runner/work/signalznow/signalznow/frontend/plugins/runtime.ts";
import timeago_wa2DbggHAx from "/home/runner/work/signalznow/signalznow/frontend/plugins/timeago.ts";
import toastification_xLadjaQwvu from "/home/runner/work/signalznow/signalznow/frontend/plugins/toastification.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/signalznow/signalznow/frontend/plugins/vuetify.ts";
export default [
  revive_payload_client_stGwXapwgk,
  unhead_MRI9ICrv4t,
  router_eCwvkSMteU,
  payload_client_rHpx0jiAot,
  navigation_repaint_client_ImPvCSBVf1,
  check_outdated_build_client_J5fogEd6UE,
  chunk_reload_client_RwuWkKbU0J,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KcpuzevjT8,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_yJlTz5TycN,
  plugin_5N3yHtt5xY,
  plugin_jAqsr7TQRA,
  plugin_pBo3AeJLa4,
  runtime_wNIQkZlad8,
  timeago_wa2DbggHAx,
  toastification_xLadjaQwvu,
  vuetify_7h9QAQEssH
]