import { S } from "effect-app"
import { EODData, Recommendation } from "./Security.js"

export class WMAEMASignal extends S.TaggedClass<WMAEMASignal>()("WMAEMASignal", {
  ...EODData.pick("open", "high", "low", "close", "date"),
  ema: S.Number,
  wma: S.Number,
  action: S.NullOr(Recommendation.fields.action)
}) {}

export class ATRSignal extends S.TaggedClass<ATRSignal>()("ATRSignal", {
  ...EODData.pick("open", "high", "low", "close", "date"),
  openHA: S.Number,
  highHA: S.Number,
  lowHA: S.Number,
  closeHA: S.Number,
  pivot: S.Number,
  trueRange: S.Number,
  atr: S.Number,
  longStop: S.Number,
  shortStop: S.Number,
  dir: S.Literal(1, -1),
  action: S.NullOr(Recommendation.fields.action)
}) {}

export class RollingSignal extends S.TaggedClass<RollingSignal>()("RollingSignal", {
  ...EODData.pick("date"),
  action: S.NullOr(Recommendation.fields.action)
}) {}

// combination of algorithms doesn't output anything else than an action
export class SimpleSignal extends S.TaggedClass<SimpleSignal>()("SimpleSignal", {
  ...EODData.pick("open", "high", "low", "close", "date"),
  action: S.NullOr(Recommendation.fields.action)
}) {}

export class SignalPerformance extends S.Class<SignalPerformance>()({
  basePerformance: S.Number,
  signalPerformance: S.Number,
  deltaPerformance: S.Number
}) {}

export class PerformanceValue extends S.Class<PerformanceValue>()({
  ...EODData.pick("open", "close", "date"),
  action: S.NullOr(Recommendation.fields.action),
  investmentValue: S.NullOr(S.Number),
  securitiesNumber: S.NullOr(S.Number)
}) {}
