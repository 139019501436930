<template>
  <v-no-ssr>
    <NuxtLayout>
      <NuxtPwaManifest />
      <NuxtPage />
    </NuxtLayout>
  </v-no-ssr>
</template>

<script setup lang="ts">
import { AccountsRsc } from "#resources"
import { Effect } from "effect"
import { clientFor, mapHandler, useSafeQuery } from "./composables/client"
import "./layouts/shared"
import { setup } from "~/composables/setup"

// Run at every startup/hard refresh

const accountsClient = clientFor(AccountsRsc)

// TODO: a Mutation is not a Query...
useSafeQuery(
  mapHandler(
    accountsClient.PingMe,
    Effect.map(() => "bogus"),
  ),
  {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: true,
  },
)

setup()
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>