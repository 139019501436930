import {
  defineNuxtRouteMiddleware,
  useRuntimeConfig,
  navigateTo,
} from "#imports"

export default defineNuxtRouteMiddleware(async to => {
  const env = useRuntimeConfig().public.env

  if (env !== "local-dev" && env !== "demo" && to.fullPath.startsWith("/buy")) {
    return navigateTo("/")
  }
})
