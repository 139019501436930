import { initializeSync } from "@effect-app/vue"
import * as Layer from "effect/Layer"
import * as Runtime from "effect/Runtime"
import { Effect, HttpClient, Option } from "@/utils/prelude"
import type {} from "#resources/lib"
import { SentrySdkLive, WebSdkLive } from "~/utils/observability"
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions"
import { ref, shallowRef } from "vue"
import { ApiClient } from "effect-app/client"
import { FetchHttpClient } from "@effect/platform"

export const versionMatch = ref(true)

export const runtime = shallowRef<ReturnType<typeof makeRuntime>>()

// TODO: make sure the runtime provides these
export type RT = ApiClient

export function makeRuntime(
  feVersion: string,
  env: string,
  isRemote: boolean,
  disableTracing: boolean,
) {
  const apiLayers = ApiClient.layer({
    url: "/api/api",
    headers: Option.none(),
  }).pipe(
    Layer.provide(
      Layer.effect(
        HttpClient.HttpClient,
        HttpClient.HttpClient.pipe(
          // Effect.map(
          //   HttpClient.tapRequest(() =>
          //     Effect.annotateCurrentSpan({
          //       "fe.device.id": deviceId,
          //       "fe.user.id": store.user?.id,
          //     }),
          //   ),
          // ),
          Effect.map(
            HttpClient.tap(r =>
              Effect.sync(() => {
                const remoteFeVersion = r.headers["x-fe-version"]
                if (remoteFeVersion) {
                  versionMatch.value = feVersion === remoteFeVersion
                }
              }),
            ),
          ),
        ),
      ),
    ),
    Layer.provide(FetchHttpClient.layer),
  )

  const otelAttrs = {
    serviceName: "getsignalz-frontend",
    serviceVersion: feVersion,
    attributes: {
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: env,
      //["fe.device.id"]: deviceId,
    },
  }

  const rt: {
    runtime: Runtime.Runtime<RT>
    clean: () => void
  } = initializeSync(
    disableTracing
      ? apiLayers
      : apiLayers.pipe(
          Layer.merge(
            isRemote ? SentrySdkLive(otelAttrs, env) : WebSdkLive(otelAttrs),
          ),
        ),
  )
  const instance = {
    ...rt,
    runFork: Runtime.runFork(rt.runtime),
    runSync: Runtime.runSync(rt.runtime),
    runPromise: Runtime.runPromise(rt.runtime),
    runCallback: Runtime.runCallback(rt.runtime),
  }

  runtime.value = instance

  return instance
}
