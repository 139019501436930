import { S } from "effect-app"
import type { Schema } from "effect-app/Schema"
import { Email, NonEmptyArray, NonEmptyString, NonEmptyString2k, optional } from "effect-app/Schema"

export const MailContent = S.Union(
  S.Struct({ text: NonEmptyString }),
  S.Struct({ html: NonEmptyString }),
  S.Struct({ templateId: NonEmptyString, dynamicTemplateData: optional(S.Record({ key: S.String, value: S.Unknown })) })
)

export type MailContent = Schema.Type<typeof MailContent>

export class MailSubjectContent extends S.ExtendedClass<MailSubjectContent, MailSubjectContent.Encoded>()({
  subject: NonEmptyString2k,
  content: MailContent
}) {}

export class SingleRecipientEmail extends S.ExtendedClass<SingleRecipientEmail, SingleRecipientEmail.Encoded>()({
  ...MailSubjectContent.fields,
  to: Email
}) {}

export class MultipleRecipientsEmail
  extends S.ExtendedClass<MultipleRecipientsEmail, MultipleRecipientsEmail.Encoded>()({
    ...MailSubjectContent.fields,
    bcc: NonEmptyArray(Email)
  })
{}

export const MailMessage = S.Union(SingleRecipientEmail, MultipleRecipientsEmail)
export type MailMessage = Schema.Type<typeof MailMessage>
// codegen:start {preset: model}
//
/* eslint-disable */
export namespace MailSubjectContent {
  export interface Encoded extends S.Struct.Encoded<typeof MailSubjectContent["fields"]> {}
}
export namespace SingleRecipientEmail {
  export interface Encoded extends S.Struct.Encoded<typeof SingleRecipientEmail["fields"]> {}
}
export namespace MultipleRecipientsEmail {
  export interface Encoded extends S.Struct.Encoded<typeof MultipleRecipientsEmail["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
//
