import { Context, Effect, identity, S } from "effect-app"
import type { NotFoundError } from "effect-app/client"
import type { Schema } from "effect-app/Schema"
import {
  NonEmptyString255,
  NonNegativeInt,
  ParseResult,
  PositiveInt,
  PositiveNumber,
  StringId
} from "effect-app/Schema"
import type { StringIdBrand } from "effect-app/Schema/moreStrings"
import type { NonEmptyString64kBrand } from "effect-app/Schema/strings"
import { extendM } from "effect-app/utils"
import type { Brand } from "effect/Brand"
import type { Simplify } from "effect/Types"
import { StockExchange } from "./StockExchange.js"
import { UserFromId, UserId } from "./User.js"

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ISINBrand extends Simplify<Brand<"ISIN"> & NonEmptyString64kBrand> {}

export type ISIN = string & ISINBrand

export const TickerSymbol = Object.assign(S.NonEmptyString255, { make: (s: string) => S.NonEmptyString255(s) })
// TODO
/*
.pipe(
  // S.filter((_) => !!_.match(new RegExp(/^\w+.\w+$/))), // TODO: not all have a dot
  S.brand("TickerSymbol")
)
*/
export type TickerSymbol = Schema.Type<typeof TickerSymbol>

// flow(S.propertySignature, S.fromKey("id"))
// TODO:
// export const PrimaryKeyAnnotation = Symbol.for("effect-app/PrimaryKey")
// export const PrimaryKey = <Key extends string>(key: Key) => S.annotations(PrimaryKeyAnnotation, key)
export const PrimaryKey = identity // TODO

export const ISIN = S
  .String
  .pipe(
    S.length(12),
    S.filter(
      (s): s is ISIN => /^[A-Z]{2}[A-Z0-9]{9}[0-9]$/.test(s),
      { description: "a valid ISIN like 'US0378331005' ", identifier: "ISIN" }
    ),
    S.withDefaultMake
  )

export const SubscriptionId = StringId
export type SubscriptionId = StringId

export const SignalType = S.Literal("BUY", "SELL", "HOLD")
export type SignalType = Schema.Type<typeof SignalType>

export class Signal extends S.ExtendedClass<Signal, Signal.Encoded>()({
  _tag: SignalType,
  at: S.Date.withDefault,
  hash: S.Number
}) {}

export class Performance extends S.ExtendedClass<Performance, Performance.Encoded>()({
  at: S.Date.withDefault,
  value: S.Number
}) {}

export class TickerData extends S.ExtendedClass<TickerData, TickerData.Encoded>()({
  hasEod: S.Boolean,
  hasIntraday: S.Boolean,
  name: S.NullOr(S.NonEmptyString255),
  stockExchange: StockExchange,
  symbol: TickerSymbol
}) {}

export class Recommendation extends S.ExtendedClass<Recommendation, Recommendation.Encoded>()({
  action: S.Literal("BUY", "SELL"),
  date: S.Date.withDefault
}) {}

export const SignalAlgorithm = S.Literal("ANDI_LONG", "ANDI_SHORT")
export type SignalAlgorithm = Schema.Type<typeof SignalAlgorithm>

export class SignalId extends S.Class<SignalId>()({
  ticker: TickerSymbol,
  algorithm: SignalAlgorithm
}) {}

export const SignalIdFromString = S.transformOrFail(
  S.String as unknown as Schema<`${Schema.Type<typeof TickerSymbol>}-${SignalAlgorithm}`>,
  S.typeSchema(SignalId),
  {
    decode: (s) =>
      Effect.gen(function*() {
        const vals = s.split("-")
        const ticker = yield* ParseResult.decodeUnknown(TickerSymbol)(vals[0])
        const algorithm = yield* ParseResult.decodeUnknown(SignalAlgorithm)(vals[1])
        return new SignalId({ ticker, algorithm })
      }),
    encode: (id) => ParseResult.succeed(`${id.ticker}-${id.algorithm}` as const)
  }
)

export class IntradayData extends S.Class<IntradayData>()({
  "open": S.NullOr(S.Number),
  "high": S.NullOr(S.Number),
  "low": S.NullOr(S.Number),
  "last": S.NullOr(S.Number),
  "close": S.NullOr(S.Number),
  "date": S.Date
}) {}

export class Translated extends S.ExtendedClass<Translated, Translated.Encoded>()({
  en: S.String,
  de: S.String
}) {}

export class NonEmptyTranslated extends S.Class<NonEmptyTranslated>()({
  en: S.NonEmptyString,
  de: S.NonEmptyString
}) {}

export class ParamsATR extends S.Class<ParamsATR>()({
  algo: S.Literal("ATR LONG", "ATR SHORT"),
  period: PositiveInt,
  multiplier: PositiveNumber
}) {}

export class ParamsM extends S.Class<ParamsM>()({
  algo: S.Literal("ROLLING"),
  unit: S.PositiveInt,
  period: PositiveInt,
  multiplier: PositiveNumber
}) {}

export const Params = S.Union(ParamsATR, ParamsM)
export type Params = typeof Params.Type

export class ImportStamp extends S.Class<ImportStamp>()({
  at: S.Date.withDefault,
  id: S.StringId.withDefault
}) {}

export class StockMeta extends S.Class<StockMeta>()({
  _tag: S.Literal("Stock")
}) {}

export class ETFMeta extends S.Class<ETFMeta>()({
  _tag: S.Literal("ETF"),

  region: Translated,
  sustainable: S.Boolean,
  style: S.NullOr(Translated)
}) {}

export const SecurityMeta = S.Union(StockMeta, ETFMeta)
export type SecurityMeta = typeof SecurityMeta.Type

export class FigiEntry extends S.Class<FigiEntry>()({
  figi: S.NonEmptyString255,
  name: S.NonEmptyString255,
  ticker: S.NonEmptyString255,
  exchCode: S.NonEmptyString255,
  compositeFIGI: S.NullOr(S.NonEmptyString255),
  securityType: S.NonEmptyString255,
  marketSector: S.NonEmptyString255,
  shareClassFIGI: S.NullOr(S.NonEmptyString255),
  securityType2: S.NonEmptyString255,
  securityDescription: S.NullOr(S.NonEmptyString255)
}) {}

export class FigiEntry2 extends S.Class<FigiEntry2>()(
  FigiEntry.pick(
    "figi",
    "ticker",
    "exchCode",
    "compositeFIGI",
    "shareClassFIGI"
    // "securityDescription" // seems useless duplicate of ticker?
  )
) {}

export class Figi extends S.Class<Figi>()({
  at: S.Date.withDefault,
  ...FigiEntry.pick("name", "securityType", "securityType2", "marketSector"),
  entries: S.NonEmptyArray(FigiEntry2),
  tickers: S.Array(TickerSymbol)
}) {}

export class Security extends S.ExtendedClass<Security, Security.Encoded>()({
  isin: PrimaryKey(ISIN),
  name: S.NonEmptyString255,
  meta: SecurityMeta,
  sector: S.NullOr(Translated),
  description: S.NullOr(Translated),
  createdAt: S.Date.withDefault,
  updatedAt: S.NullOr(S.Date).withDefault,
  logo: S.NullOr(S.Url).withDefault,
  summary: S.NullOr(Translated).withDefault,
  import: ImportStamp
}) {
  static readonly resolver = Context.GenericTag<SecurityFromId, (id: ISIN) => Effect<Security, NotFoundError>>(
    "SecurityFromId"
  )
}

export interface SecurityFromId {
  readonly _: unique symbol
}

export const SecurityFromId: Schema<Security, string, SecurityFromId> = S.transformOrFail(
  ISIN,
  S.typeSchema(Security),
  {
    decode: (id) =>
      Security.resolver.pipe(
        Effect.andThen((_) => _(id)),
        Effect.mapError((error) => new S.ParseResult.Unexpected(null, error.message))
      ),
    encode: (u) => ParseResult.try({ try: () => u.isin, catch: (e) => new S.ParseResult.Unexpected(u, `${e}`) })
  }
)

export class Ticker extends S.ExtendedClass<Ticker, Ticker.Encoded>()({
  symbol: PrimaryKey(TickerSymbol),
  name: S.NullOr(S.NonEmptyString255),
  isin: ISIN,
  stockExchange: StockExchange,
  index: S.Array(S.NonEmptyString255).withDefault, // blank for etfs atm
  hasEod: S.Boolean,
  hasIntraday: S.Boolean,
  intraday: S.NullOr(IntradayData).withDefault,
  signals: S.ReadonlyMap({ key: SignalAlgorithm, value: S.NullOr(Recommendation) }).withDefault,
  entry: Params,
  exit: Params
}) {
  static readonly resolverFromSecurityId = Context.GenericTag<
    TickersFromSecurityId,
    (id: ISIN) => Effect<readonly Ticker[]>
  >(
    "TickersFromSecurityId"
  )
}

export class TickerWithSecurity extends S.ExtendedClass<TickerWithSecurity, TickerWithSecurity.Encoded>()({
  ...Ticker.omit("isin"),
  security: SecurityFromId.pipe(S.propertySignature, S.fromKey("isin"))
}) {
  static readonly resolverFromSecurityId = Context.GenericTag<
    TickersFromSecurityId,
    (id: ISIN) => Effect<readonly Ticker[]>
  >(
    "TickersFromSecurityId"
  )
}

export interface TickersFromSecurityId {
  readonly _: unique symbol
}

export const TickersFromSecurityId: Schema<readonly Ticker[], string, TickersFromSecurityId> = S.transformToOrFail(
  ISIN,
  S.Array(S.typeSchema(Ticker)),
  (id) => Effect.andThen(Ticker.resolverFromSecurityId, (_) => _(id))
)

const author = UserFromId
  .pipe(S.propertySignature, S.fromKey("authorId"))

export class TickerComment extends S.Class<TickerComment>()({
  id: StringId.withDefault,
  author,
  createdAt: S.Date.withDefault,
  content: NonEmptyString255,
  tickerId: TickerSymbol
}) {}

export class PortfolioTicker extends S.ExtendedClass<PortfolioTicker, PortfolioTicker.Encoded>()({
  subscribedAlgorithms: S.ReadonlyMap({ key: SignalAlgorithm, value: S.NullOr(Recommendation) }).withDefault, // TODO: track the current signal upon subscription, then send change notifications when the signal is different from the point in time signal.
  owned: S.Boolean.withDefault
}) {}

export class EODData extends S.ExtendedClass<EODData, EODData.Encoded>()({
  "open": S.Number,
  "high": S.Number,
  "low": S.Number,
  "close": S.Number,
  "volume": S.NullOr(NonNegativeInt),
  "adjHigh": S.NullOr(S.Number),
  "adjLow": S.NullOr(S.Number),
  "adjClose": S.NullOr(S.Number),
  "adjOpen": S.NullOr(S.Number),
  "adjVolume": S.NullOr(NonNegativeInt),
  "splitFactor": S.Number,
  "dividend": S.Number,
  // "exchange": MIC,
  date: S.Date
}) {
}

export class TickerEOD extends S.ExtendedClass<TickerEOD, TickerEOD.Encoded>()({
  id: NonEmptyString255, // symbol
  /**
   * Stored in reverse: latest date first
   */
  data: S.Array(EODData)
}) {}

export interface PortfolioIdBrand extends Simplify<Brand<"PortfolioId"> & StringIdBrand> {
  readonly PortfolioId: unique symbol
}
export const PortfolioId = extendM(S.prefixedStringId<PortfolioId>()("pf", "Portfolio"), () => ({
  fromUserId: (id: UserId) => PortfolioId("pf-" + id),
  toUserId: (id: PortfolioId) => UserId(id.replace("pf-", ""))
}))
export type PortfolioId = StringId & PortfolioIdBrand

export class PortfolioV2 extends S.ExtendedClass<PortfolioV2, PortfolioV2.Encoded>()({
  id: PortfolioId,
  tickers: S.ReadonlyMap({ key: TickerSymbol, value: PortfolioTicker }).withDefault,
  lastNotificationSentAt: S.NullOr(S.Date).withDefault
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace Signal {
  export interface Encoded extends S.Struct.Encoded<typeof Signal["fields"]> {}
}
export namespace Performance {
  export interface Encoded extends S.Struct.Encoded<typeof Performance["fields"]> {}
}
export namespace TickerData {
  export interface Encoded extends S.Struct.Encoded<typeof TickerData["fields"]> {}
}
export namespace Recommendation {
  export interface Encoded extends S.Struct.Encoded<typeof Recommendation["fields"]> {}
}
export namespace Translated {
  export interface Encoded extends S.Struct.Encoded<typeof Translated["fields"]> {}
}
export namespace Security {
  export interface Encoded extends S.Struct.Encoded<typeof Security["fields"]> {}
}
export namespace Ticker {
  export interface Encoded extends S.Struct.Encoded<typeof Ticker["fields"]> {}
}
export namespace TickerWithSecurity {
  export interface Encoded extends S.Struct.Encoded<typeof TickerWithSecurity["fields"]> {}
}
export namespace PortfolioTicker {
  export interface Encoded extends S.Struct.Encoded<typeof PortfolioTicker["fields"]> {}
}
export namespace EODData {
  export interface Encoded extends S.Struct.Encoded<typeof EODData["fields"]> {}
}
export namespace TickerEOD {
  export interface Encoded extends S.Struct.Encoded<typeof TickerEOD["fields"]> {}
}
export namespace PortfolioV2 {
  export interface Encoded extends S.Struct.Encoded<typeof PortfolioV2["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
